<template>
    <div class="documents flex-column relative" v-if="hasMainLayoutInitialized">
       <DocumentsHeader @set-view="setView" :data="popOverHeaderData" :viewType="viewType"/>
       <div class="documents__content">
          <el-scrollbar v-if="!isLoading">
              <div class="documents__scrollbar-content flex-column">
                <StoragePlanBox :property="property"/>
                <DocumentsLayoutSlot :titlePath="titlePath" :viewType="viewType" @on-view-folder="onViewFolder" v-if="folderList.length !== 0 || titlePath.length !== 0">
                    <DocumentListTypeComponent
                      v-if="viewType === 'list'"
                      type="folder"
                      :data="popOverData"
                      :list="folderList"
                      @on-view-folder="onViewFolder"
                    />
                    <!-- <DocumentTileTypeFileItemComponent v-for="(item, index) in fileCount" :key="index" :data="popOverData"/> -->
                </DocumentsLayoutSlot>

                <DocumentsLayoutSlot :titlePath="fileTitle" :viewType="viewType" v-if="fileList.length !== 0">
                    <DocumentListTypeComponent
                      v-if="viewType === 'list'"
                      type="file"
                      :data="popOverData"
                      :list="fileList"
                    />
                </DocumentsLayoutSlot>

                <DocumentEmptyStateComponent v-if="fileList.length === 0 && folderList.length === 0 && viewType === 'list'"/>
                <!-- TEMPORARY -->
                  <div class="documents__coming-soon flex-column jc-c ai-c" v-show="viewType === 'tile'">
                    <div class="documents__img-wrapper flex-column jc-c ai-c">
                      <img src="@/assets/images/construction-gif.gif"/>
                      <p class="documents__construction-text default-text">This section is under<br> construction</p>
                    </div>
                  </div>
                <!-- TEMPORARY -->
              </div>
          </el-scrollbar>
          <Loader v-show="isLoading"/>
       </div>

       <!-- MODAL -->
       <CreateNewFolderModal :show="openNewFolderModal" @close="setOpenNewFolderModal(false)"/>
       <FileUploadModal :show="openNewFileUploadModal" @close="setOpenNewFileUploadModal(false)"/>
       <RenameModal :show="openRenameModal" @close="setOpenRenameModal(false)"/>
       <DeleteConfirmationModal :show="openDeleteModal" @close="setOpenDeleteModal(false)"/>
       <MediaDetailsModal :show="openDetailsModal" @close="setOpenDetailsModal(false)"/>
       <DocumentsShareModal :show="openShareModal" @close="setOpenShareModal(false)"/>
       <MoveModal :show="openMoveModal" @close="setOpenMoveModal(false)"/>
       <DocumentBillingModal :show="openDocumentBillingModal" @close="setOpenDocumentBillingModal(false)"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DocumentEmptyStateComponent from '@/core/components/documents/DocumentEmptyStateComponent.vue';
import DocumentListTypeComponent from '@/core/components/documents/DocumentListTypeComponent.vue';
import DocumentsHeader from '@/core/components/documents/DocumentsHeader.vue';
import DocumentsLayoutSlot from '@/core/components/documents/DocumentsLayoutSlot.vue';
// import DocumentTileTypeFileItemComponent from '@/core/components/documents/DocumentTileTypeFileItemComponent.vue';
// import DocumentTileTypeFolderItemComponent from '@/core/components/documents/DocumentTileTypeFolderItemComponent.vue';
import CreateNewFolderModal from '@/core/components/documents/modal/CreateNewFolderModal.vue';
import DeleteConfirmationModal from '@/core/components/documents/modal/DeleteConfirmationModal.vue';
import DocumentBillingModal from '@/core/components/documents/modal/DocumentBillingModal.vue';
import DocumentsShareModal from '@/core/components/documents/modal/DocumentsShareModal.vue';
import FileUploadModal from '@/core/components/documents/modal/FileUploadModal.vue';
import MediaDetailsModal from '@/core/components/documents/modal/MediaDetailsModal.vue';
import MoveModal from '@/core/components/documents/modal/MoveModal.vue';
import RenameModal from '@/core/components/documents/modal/RenameModal.vue';
import StoragePlanBox from '@/core/components/documents/StoragePlanBox.vue';
import Loader from '@/core/components/ui/Loader.vue';
import $axios from '@/core/utils/axios-api-config';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    DocumentsHeader,
    StoragePlanBox,
    DocumentsLayoutSlot,
    // DocumentTileTypeFolderItemComponent,
    // DocumentTileTypeFileItemComponent,
    DocumentListTypeComponent,
    CreateNewFolderModal,
    FileUploadModal,
    RenameModal,
    DeleteConfirmationModal,
    MediaDetailsModal,
    DocumentsShareModal,
    MoveModal,
    DocumentEmptyStateComponent,
    DocumentBillingModal,
    Loader
  },
  data() {
    return {
      folderCount: 6,
      fileCount: 16,
      viewType: 'list',
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/filled-share-icon.svg')}`,
          name: 'Share',
          event: 'openModalShare',
        },
        {
          icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
          name: 'Rename',
          event: 'openModalRename',
        },
        {
          icon: `${require('@/assets/icons/filled-move-icon.svg')}`,
          name: 'Move to',
          event: 'openModalMove',
        },
        {
          icon: `${require('@/assets/icons/filled-details-icon.svg')}`,
          name: 'Details',
          event: 'openModalDetails',
        },
        {
          icon: `${require('@/assets/icons/filled-download-icon.svg')}`,
          name: 'Download',
          event: 'downloadDocument',
        },
        {
          icon: `${require('@/assets/icons/delete-icon.svg')}`,
          name: 'Delete',
          event: 'openModalDelete',
        },
      ],
      popOverHeaderData: [
        {
          icon: `${require('@/assets/icons/upload-icon.svg')}`,
          name: 'File upload',
          event: 'openFileUploadModal',
        },
        // {
        //   icon: `${require('@/assets/icons/filled-gray-folder-icon.svg')}`,
        //   name: 'Folder upload',
        //   event: 'folderUpload',
        // },
        {
          icon: `${require('@/assets/icons/create-new-folder-icon.svg')}`,
          name: 'New folder',
          event: 'openModalNewFolder',
        },
      ],
      /* eslint-disable global-require */

      defaultTitlePath: {
        name: 'Folders',
      },
      titlePath: [],
      fileTitle: [{ name: 'Files' }],
      currentFolderList: [],
      currentFileList: [],

      property: null
    };
  },
  computed: {
    ...mapGetters(['isLoading']),

    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters(DOCUMENTS_STORE, [
      'openShareModal',
      'openRenameModal',
      'openMoveModal',
      'openDetailsModal',
      'openDeleteModal',
      'openNewFolderModal',
      'openNewFileUploadModal',
      'openDocumentBillingModal',

      'folderList',
      'fileList',
      'openedFolder'
    ]),

    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },

  async created() {
    this.setFolderList([]);
    this.setFileList([]);

    await this.setProperty();
    await this.getList();

    // check successful transaction
    this.checkSuccessfulTransaction();
    this.setIsLoading(false);
  },

  methods: {
    ...mapActions(DOCUMENTS_STORE, [
      'setOpenShareModal',
      'setOpenRenameModal',
      'setOpenMoveModal',
      'setOpenDetailsModal',
      'setOpenDeleteModal',
      'setOpenNewFolderModal',
      'setOpenNewFileUploadModal',
      'setOpenDocumentBillingModal',

      'getUserDocuments',
      'setFolderList',
      'setFileList',
      'setOpenedFolder',
      'getUserDocumentCalculatedSize',
      'setIsPropertyDocument'
    ]),
    ...mapActions(PROPERTY_STORE, ['getProperty']),

    ...mapActions(['setPaymentSuccessfulModal', 'setIsLoading']),

    async getList(options = {}) {
      const { userId } = this.authenticatedUser;
      const params = { userId };
      const { parentId } = options;
      const hasParentParam = parentId || null;
      const documentPromises = [];

      if (Object.keys(options).length) {
        params.options = options;
      }

      this.resetTitlePath();

      await this.getUserDocuments(params)
        .then(async (userDocuments) => {
          this.currentFolderList = userDocuments.filter((userDocument) => userDocument.isDir === true
            && userDocument.parentId === hasParentParam);

          this.currentFileList = userDocuments.filter((userDocument) => !userDocument.isDir
            && userDocument.parentId === hasParentParam);
          this.currentFileList = this.currentFileList.map((file) => {
            return { ...file, name: file.originalName, attachment: file.name };
          });

          this.currentFolderList.map((folderItem) => {
            documentPromises.push(this.getUserDocumentCalculatedSize({
              userId,
              userDocumentId: folderItem.id
            }));

            return {
              folderItem
            };
          });

          if (documentPromises.length) {
            const documentFolderSizes = await Promise.all(documentPromises);
            this.currentFolderList = this.currentFolderList.map((item) => {
              const findFolder = documentFolderSizes.find(((x) => x.id === item.id));

              return { ...item, ...findFolder };
            });
          }

          this.setFolderList(this.currentFolderList);
          this.setFileList(this.currentFileList);
        });
    },

    async onViewFolder(folder = {}) {
      const { id } = folder;
      let params = {};

      if ('id' in folder) {
        params = { parentId: id };
      }

      this.setOpenedFolder(folder);

      await this.getList(params)
        .then(() => {
          if (id) {
            this.titlePath.push(folder);
          }
        });
    },

    resetTitlePath() {
      this.titlePath = [this.defaultTitlePath];
    },

    setView(event) {
      this.viewType = event;
    },

    async setProperty() {
      const { params } = this.$route;
      const propertyId = params.id;
      this.setIsPropertyDocument(propertyId != null);

      this.property = await this.getProperty(propertyId);
    },

    async checkSuccessfulTransaction() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const { userId } = this.authenticatedUser;

      if (params.session_id) {
        const { params: routeParams } = this.$route;
        const propertyId = routeParams.id;
        const url = `/payment/${userId}/session?sessionId=${params.session_id}&propertyId=${propertyId}`;

        await $axios.get(url)
          .then(async (sessionResponse) => {
            if (sessionResponse) {
              this.setPaymentSuccessfulModal(true);
              await this.setProperty();
            }
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.documents {
    max-width: 1230px;
    margin-left: 10px;
    margin-right: 25px;
    height: calc(100% - 3.2rem);

    &__content {
      height: calc(100% - 55px);
    }
    &__scrollbar-content {
      padding: 0 .7rem 0 0;
      gap: 2rem;
      height: 100%;
    }

    &__coming-soon {
      width: 100%;
      height: 100%;
    }
    &__img-wrapper {
      width: 300px;
      height: 250px;
      margin-top: -10rem;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &__construction-text {
      font-size: 25px;
      line-height: 30px;
      font-weight: 700;
      text-align: center;
      color: rgba(12, 15, 74, 0.5);
    }
}
// :deep(.el-scrollbar__bar) {
//     display: block !important;
//   //  :deep(.el-scrollbar__view) {
//   //    padding-bottom: 2rem;
//   //  }
// }
// :deep(.el-scrollbar__bar.is-vertical) {
//     top: 0;
//     right: 0;
//     width: 6px !important;
// }

@include media-md-max-width() {
  .documents {
    margin: 0 5px;
    height: calc(100% - 2.2rem);
    &__content {
      height: calc(100% - 102px);
    }
    &__scrollbar-content {
      padding: 0 .6rem 2rem 0;
    }
  }
}
@include media-sm-max-width() {
  .documents {
    height: calc(100% - .4rem);
    &__content {
      height: calc(100% - 100px);
    }
  }
}
@include media-sm-max-width() {
  .documents {
    height: calc(100% - .1rem);
  }
}
</style>
